import { mapState } from "vuex";

export default {
  data() {
    return {
      isDeviceSmall: false,
    };
  },
  computed: {
    windowSize() {
      return this.$store.getters["app/currentBreakPoint"];
    },
    ...mapState({
      allModalsState: (state) => state.modals,
    }),
  },
  watch: {
    windowSize(newVal) {
      this.checkDeviceSize(newVal);
    },
    allModalsState: {
      immediate: true,
      deep: true,
      handler(newVal) {
        const isAnyValueTrue = Object.values(newVal).some(
          (value) => value === true
        );
        isAnyValueTrue && this.isUserLoggedIn
          ? (document.body.className += " overflow-hidden")
          : document.body.classList.remove("overflow-hidden");

        if (!isAnyValueTrue) {
          window.history.replaceState(
            null,
            null,
            window.location.href.split("#")[0]
          );
        }
      },
    },
  },
  mounted() {
    this.checkDeviceSize(this.windowSize);
  },
  methods: {
    checkDeviceSize(val) {
      if (val === "sm" || val === "xs") {
        return (this.isDeviceSmall = true);
      }
    },
    setOrgColor(color) {
      let domStyle = document.documentElement.style;
      domStyle.setProperty("--org-theme-color", color);
      domStyle.setProperty("--org-theme-color--low", color + "1f");
    },
    orgBg(color) {
      return {
        background: `linear-gradient(${color + "BF"}, ${
          color + "BF"
        }), url(${require(`@/assets/images/gala-bg.png`)})`,
      };
    },
    suggestionBg(color) {
      return {
        background: `${color}1a`,
      };
    },
    blockBodyScroll(val) {
      return val === true
        ? (document.body.className += " overflow-hidden")
        : document.body.classList.remove("overflow-hidden");
    },
  },
};
