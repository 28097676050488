<template>
  <label class="toggle-switch mb-0">
    <input type="checkbox" class="toggle-checkbox" v-model="toggleValue" />
    <div class="toggle-label">
      <div
        class="toggle-photo"
        :class="toggleValue ? '' : 'toggle-photo__org'"
        :style="{
          backgroundImage: getImg(),
        }"
      ></div>
      <div class="toggle-text" @click="$store.commit('library/UPDATE_SEARCH_ITEM_QUERY', '')">
        Switch to {{ toggleValue ? "User" : "Org" }}
      </div>
    </div>
  </label>
</template>

<script>
import constants from "@/constants";
import orgMixin from "@/mixins/orgMixin.js";
export default {
  name: "ToogleSwitch",
  mixins: [orgMixin],
  data() {
    return {
      constants,
      toggleValue: false,
      imgSrc: "",
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.userData;
    },
    avatarNameGenerator() {
      return this.$store.state.user.avatarNameGenerator;
    },
  },
  watch: {
    toggleValue(newVal) {
      if (newVal) {
        this.$store.commit("user/SET_USER_VIEW", "organizationView");
      } else {
        this.$store.commit("user/SET_USER_VIEW", "userView");
      }
    },
  },
  methods: {
    getImg() {
      if (this.toggleValue) {
        if (this.userData.profilePictureUrl) {
          return `url(${constants.s3Bucket}/editor/profile-pictures/${this.userData.profilePictureUrl})`;
        } else {
          return `url(https://source.boringavatars.com/beam/100/${this.avatarNameGenerator(
            this.userData.firstName,
            this.userData.lastName
          )})`;
        }
      } else {
        if (this.orgObj && this.orgObj.org) {
          return `url(${require(`@/assets/images/organization/${this.orgObj.org}/logo.png`)})`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Toggle Switch */
.toggle-switch {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
}

.toggle-switch .toggle-checkbox {
  display: none;
}

/* Toggle Label */
.toggle-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e82b51;
  color: #fff;
  border-radius: 2em;
  padding: 4px 10px;
  transition: background-color 0.3s;
}

/* Toggle Photo */
.toggle-photo {
  background-image: url("../../assets/images/organization/default/logo.png");
  background-size: cover;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 7px;
  transition: transform 0.3s;
  &__org {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

/* Toggle Text */
.toggle-text {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  transition: transform 0.3s;
}

/* Toggle Switch Styling */
.toggle-switch .toggle-checkbox:checked + .toggle-label {
  background-color: var(--org-theme-color);
}

.toggle-switch .toggle-checkbox:checked + .toggle-label .toggle-photo {
  transform: translateX(100px);
}

.toggle-switch .toggle-checkbox:checked + .toggle-label .toggle-text {
  transform: translateX(-40px);
}

@media only screen and (max-width: 991px) {
  /* Toggle Photo */
  .toggle-photo {
    width: 35px;
    height: 35px;
  }
}
</style>
