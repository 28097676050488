import organization from "@/assets/data/organizationConfig.js";
export default {
  data() {
    return {
      timeZone: "",
      orgObj: {},
    };
  },
  watch: {
    // orgObj: {
    //   handler(newVal, oldVal) {
    //     if (
    //       newVal &&
    //       oldVal &&
    //       newVal !== oldVal
    //     ) {
    //       this.$store
    //         .dispatch("user/fetchOrganizationDetails", newVal.gborgid)
    //         .then((res) => {
    //           this.orgObj = { ...newVal, ...res.data.data };
    //           console.log(this.orgObj);
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  created() {
    this.findOrg();
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  computed: {
    userData() {
      this.$store.state.user.userData;
    },
  },
  methods: {
    findOrg() {
      if (this.$route.query.org) {
        this.orgObj = organization.find((organization) => {
          return organization.orgSlug === this.$route.query.org;
        });
        if (this.orgObj === null || this.orgObj === undefined) {
          this.orgObj = {
            orgSlug: "default",
            orgColor: "#e82b51",
          };
        }
      } else {
        this.orgObj = {
          orgSlug: "default",
          orgColor: "#e82b51",
        };
      }
      this.orgObj = Object.assign(this.orgObj, this.$route.query);
      if (this.userData?._id) {
        this.orgObj.gbuid = this.userData._id;
        this.orgObj.email = this.userData.email;
        this.orgObj.first_name = this.userData.firstName;
      }
    },
  },
};
