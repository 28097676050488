<template>
  <div
    @click="
      $store.commit('modals/OPEN_EDITOR_POPUP', false);
      blockBodyScroll(false);
    "
    class="py-2 md-pd d-flex align-items-center w-100 h-100 justify-content-center editor__background"
  >
    <section
      @click.stop
      class="w-60 p-2 editor position-relative"
      :class="`light-theme`"
    >
      <div
        @click="
          $store.commit('modals/OPEN_EDITOR_POPUP', false);
          blockBodyScroll(false);
        "
        class="d-flex position-absolute align-items-center justify-content-center cross-icon cursor-pointer"
        :class="`cross-icon-light`"
      >
      <img src="@/assets/images/icons/cross-icon.svg" alt="">
      </div>
      <iframe
        class="w-100 h-100"
        :src="`${constants.editorUrl}/oauth-callback?organization=${orgSlug}&token=${authDetails.accessToken}&userId=${authDetails.user._id}`"
      ></iframe>
    </section>
  </div>
</template>
<script>
import constants from "@/constants";
import designMixin from "@/mixins/designMixin.js";

export default {
  name: "EditorModal",
  data() {
    return {
      constants,
    };
  },
  mixins: [designMixin],

  props: {
    orgSlug: {
      type: String,
    },
  },
  computed: {
    authDetails() {
      return this.$store.state.auth;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.light-theme {
  background: #fff;

  .text {
    color: #1c1c1e;
  }
}

.cross-icon {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  top: 0;
  font-size: 1.5em;
  padding: 0.5em;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  top: -0.5em;
  right: -0.5em;
  transition: all 0.23s ease 0.1s;
  transform: translate(8px, -2px);
  font-family: "Montserrat", Helvetica, Arial, serif;

  &-light {
    background: #fffffffa;
  }
  // For hover effect of close btn
  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    outline: none;
    transform: translate(5px, 3px);
    box-shadow: none;
  }
}

.editor {
  width: 97.5%;
  height: 100%;
  border-radius: 10px;

  &__background {
    background: #0000006a;
    backdrop-filter: blur(5px);
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
  }

  &__heading {
    &--1 {
      font-size: 1.75em;
    }
  }
}

@media only screen and (max-width: 991px) {
  .md-pd {
    padding: 1.5em !important;
  }
}

@media only screen and (max-width: 660px) {
  .editor {
    &__heading {
      &--1 {
        font-size: 1.5em;
      }
    }
  }
}
</style>
