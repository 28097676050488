// the organization which have animation for activation of the bot the name of the organization is to be specified and the duration of the gif provided

let organization = [
  {
    orgSlug: "gala",
    isChatBotAnimation: true,
    gifTime: 11500,
    orgColor: "#3d92fc",
    softSkillFormId: "qxBZdrc2",
    techSkillFormId: "naWTlhB2",
    eventFormId: "r4fljGXc",
    alexName: "alvox",
  },
  {
    orgSlug: "datarmony",
    isChatBotAnimation: false,
    orgColor: "#3d92fc",
    softSkillFormId: "zGCjiJT4",
    techSkillFormId: "zSzMbnMm",
    eventFormId: "wNegDqv1",
  },
  {
    orgSlug: "sitel-learning",
    isChatBotAnimation: false,
    orgColor: "#E71B44",
    softSkillFormId: "v9lBB06Z",
    techSkillFormId: "LEXY46Yy",
    eventFormId: "rKw3FXDi",
  },
  //eu-business-school
  {
    orgSlug: "euruni",
    isChatBotAnimation: false,
    orgColor: "#00BE96",
    softSkillFormId: "ChxB4kCG",
    techSkillFormId: "pq7hjovQ",
    eventFormId: "xv4GXPCs",
    alexName: "alfred",
  },
  //ie-business-school
  {
    orgSlug: "ie",
    isChatBotAnimation: false,
    orgColor: "#000066",
    softSkillFormId: "GM9HOQPl",
    techSkillFormId: "CgaO36KN",
    eventFormId: "gRdTqLDF",
    alexName: "alfred",
  },
  //foundever
  {
    orgSlug: "foundever",
    isChatBotAnimation: false,
    orgColor: "#4A4AF9",
    softSkillFormId: "LpioKmLN",
    techSkillFormId: "MuglgjeS",
    eventFormId: "efR4lqWG",
    alexName: "alex",
  },
  {
    orgSlug: "altf",
    isChatBotAnimation: false,
    orgColor: "#ec9716",
    softSkillFormId: "O1qIDEsK",
    techSkillFormId: "CcCXCRuO",
    eventFormId: "O7hhj1Ag",
    alexName: "albert",
  },
  //sandbox accounts for testing on local
  {
    orgSlug: "sitel-learning-sandbox",
    isChatBotAnimation: false,
    orgColor: "#E71B44",
    softSkillFormId: "zGCjiJT42",
    techSkillFormId: "zSzMbnMm",
    eventFormId: "rKw3FXDi",
  },
];
export default organization;


