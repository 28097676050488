<template>
  <b-row
    class="d-flex justify-content-between align-items-center navbar__container w-100 my-3"
    :style="{ 'max-width': '1240px' }"
  >
    <b-col cols="3" class="p-0"
      ><div class="cursor-pointer" @click="routeToLibrary">
        <img
          class="company-logo"
          src="@/assets/images/library-logo-pink.svg"
          alt="Library"
        /></div
    ></b-col>
    <b-col class="d-flex align-items-center justify-content-end pr-0">
      <!-- <div
        @click="changeTheme"
        class="cursor-pointer d-flex align-items-center justify-content-center mx-1 mx-md-2"
      >
        <feather-icon
          :color="orgColor"
          :size="isDeviceSmall ? '25' : '35'"
          :icon="`${currentTheme === 'light' ? 'Sun' : 'Moon'}Icon`"
        />
      </div> -->
      <unified-menu-custom class="mr-50" v-if="isUserLoggedIn" />
      <!-- <unified-menu class="mr-50" v-if="isUserLoggedIn" /> -->
      <div class="responsive">
        <div class="dropdown" :style="profileDropdown ? 'display : block' : ''">
          <toggle-switch
            v-if="userAccessStatus == 'admin' && isUserLoggedIn"
            class="mx-50"
          />
        </div>
        <img
          v-if="userData"
          variant="info"
          :src="getImg()"
          size="3.5em"
          class="ml-50 selected__img cursor-pointer"
          :class="appView === 'organizationView' ? 'selected__img__org' : ''"
          :style="
            appView === 'organizationView' ? `background : ${orgColor}` : ''
          "
          @click="toggleProfileDrop"
        />
      </div>
      <!-- <div
        class="cursor-pointer custom-btn d-none d-md-flex align-items-center justify-content-center ml-75"
        :class="appView === 'organizationView' ? '' : 'custom-btn__user-view'"
        @click="
          $store.commit('modals/OPEN_EDITOR_POPUP', true);
          blockBodyScroll(true);
        "
      >
        <p class="m-0 custom-btn-text">My Profile</p>
      </div> -->

      <div
        class="cursor-pointer d-flex d-md-none align-items-center justify-content-center"
        @click="
          $store.commit('modals/OPEN_EDITOR_POPUP', true);
          blockBodyScroll(true);
        "
      >
        <!-- <feather-icon
          :color="appView === 'organizationView' ? orgColor : '#e82b51'"
          :size="isDeviceSmall ? '27' : '32'"
          icon="UserIcon"
        /> -->
      </div>
    </b-col>
    <editor-modal v-if="editorPopup" :orgSlug="orgObj.orgSlug" />
  </b-row>
</template>

<script>
import constants from "@/constants";
import { BRow, BCol } from "bootstrap-vue";
import designMixin from "@/mixins/designMixin.js";
import orgMixin from "@/mixins/orgMixin.js";
import ToggleSwitch from "./common/ToggleSwitch.vue";
import EditorModal from "@/modals/EditorModal.vue";
// import UnifiedMenu from "./UnifiedMenu.vue";
import UnifiedMenuCustom from "./common/UnifiedMenu.vue";
export default {
  name: "Navbar",
  mixins: [orgMixin, designMixin],
  components: {
    BCol,
    BRow,
    ToggleSwitch,
    EditorModal,
    // UnifiedMenu,
    UnifiedMenuCustom,
  },
  data() {
    return {
      constants,
      orgColor: "",
      profileDropdown: false,
    };
  },
  computed: {
    userAccessStatus() {
      return this.$store.state.user.userAccessStatus;
    },
    editorPopup() {
      return this.$store.state.modals.editorPopup;
    },
    appView() {
      return this.$store.state.user.appView;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    avatarNameGenerator() {
      return this.$store.state.user.avatarNameGenerator;
    },
    isUserLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn;
    },
  },
  created() {
    this.orgColor = this.orgObj.orgColor;
    const { gbuid, gborgid } = this.$route.query;
    this.findOrg();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "gtm.click",
      userId: gbuid,
      orgId: gborgid,
    });
  },
  mounted() {
    this.getUserStatus();
    this.getOrgData();
  },
  methods: {
    toggleProfileDrop() {
      const screenWidth = screen.width;
      if (screenWidth > 480) {
        this.profileDropdown = false;
      } else {
        this.profileDropdown = !this.profileDropdown;
      }
    },
    getUserStatus() {
      if (this.isUserLoggedIn) {
        this.$store
          .dispatch("user/userStatus", this.orgObj)
          .then((res) => {
            if (res?.data?.accessLevel === "admin") {
              this.$store.commit(
                "user/SET_USER_ACCESS_STATUS",
                res.data.accessLevel
              );
            } else {
              this.$store.commit("user/SET_USER_ACCESS_STATUS", "member");
            }
          })
          .catch(() => {
            // console.log(err);
            return;
          });
      }
    },
    getImg() {
      if (this.appView === "userView") {
        if (this.userData && this.userData.profilePictureUrl) {
          return `${constants.s3Bucket}/editor/profile-pictures/${this.userData.profilePictureUrl}`;
        } else {
          let firstName =
            this.userData && this.userData.firstName
              ? this.userData.firstName
              : "boringAvatar";
          let lastName =
            this.userData && this.userData.lastName
              ? this.userData.lastName
              : "boringAvatar";
          return `https://source.boringavatars.com/beam/100/${this.avatarNameGenerator(
            firstName,
            lastName
          )}`;
        }
      } else {
        if (this.orgObj && this.orgObj.org) {
          return `${require(`@/assets/images/organization/${this.orgObj.org}/logo.png`)}`;
        }
      }
    },
    getOrgData() {
      this.$store
        .dispatch("user/fetchOrganizationDetails", this.orgObj?.gborgid)
        .then((res) => {
          this.orgObj = { ...this.orgObj, ...res.data.data };
          this.$store.commit("user/SET_ACTIVE_ORG_DATA", this.orgObj);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    routeToLibrary() {
      const { form_id, first_name, email, org, gbuid, gborgid } =
        this.$route.query;
      let routingUrl = `/library?form_id=${form_id}&first_name=${first_name}&email=${email}&org=${org}&gbuid=${gbuid}&gborgid=${gborgid}`;

      this.$router.push(routingUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
// .navbar__container {
//   // border-bottom: 2px solid $gray-300;
// }

.selected__img {
  width: 3.25em;
  height: 3.25em;
  border-radius: 50%;
  object-fit: cover;
  &__org {
    padding: 0.5em;
    object-fit: contain;
  }
}
.custom-btn {
  &__user-view {
    background: #e82b51;
  }
}

.responsive {
  position: relative;
  display: flex;
}

@media (max-width: 480px) {
  .dropdown {
    position: absolute;
    display: none;
    width: max-content;
    margin-top: 1em;
    padding: 1em;
    background-color: white;
    top: 100%;
    right: 0px;
    border-radius: 8px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11);
  }
}
</style>
